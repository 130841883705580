<button
    class="avatar-language-container"
    [disabled]="!isInteractionAllowed"
    (click)="toggleDropdown()"
    (keydown)="toggleDropdown()"
    tabindex="0"
>
    <div class="avatar-languageMenu" *ngIf="dropDownVisible">
        <div *ngFor="let language of languages">
            <div
                class="avatar-menuItem"
                [ngClass]="getClass(language)"
                (click)="toggleLanguage(language)"
                (keydown)="toggleLanguage(language)"
                tabindex="0"
            >
                <img [src]="language.icon" alt="Language icon">
                <p [dir]="language.direction" >{{ language.name }}</p>
            </div>
        </div>
    </div>
    <div>
        <img [src]="selectedLanguage.icon" alt="Selected language icon">
    </div>
</button>
