import { Component, OnInit} from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { ComponentAbstract } from "app/components/component.abstract";
import { LanguageService } from "app/services/language.service";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent extends ComponentAbstract implements OnInit {
    companyLogo: string = "assets/icons/logo.svg"
    welcomeHeader: string = "Where Innovation Meets Intelligent Interaction"
    welcomeDescription: string =
        `We are passionately committed to elevating our partners’ performance. We improve efficiency,
        increase value, and grow genuine partnerships. We create mutual success and champion both local talent
        and the latest technologies.`
    textDirection!: string;

    translateSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private language: LanguageService,
        private title: Title
    ) {
        super(config);
        this.title.setTitle(this.config.getProjectName());
    }

    ngOnInit() {
        console.log(environment)

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale)

            Object.assign(this, translate.typography)
        });
    }
}
