import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
    interactionAllowed = new BehaviorSubject<boolean>(false);
    interactionAllowed$ = this.interactionAllowed.asObservable();

    constructor() { }

    //getters
    getInteractionMode (): Observable<boolean> {
        return this.interactionAllowed;
    }

    //setters
    setInteractionMode (mode: boolean) {
        this.interactionAllowed.next(mode);
    }
}
