<div *ngIf="isVisible" class="avatar-navbar-container">
    <button [disabled]="!isInteractionAllowed" type="button" class="avatar-shortcuts-button" (click)="onShowShortcuts()">
        <img alt="Shortcuts icon">
    </button>
    <button [disabled]="!isInteractionAllowed" type="button" class="avatar-att" (click)="onOpenAttachments()">
        <app-attachment [fillColor]="currentAttachmentColor" width="19px" height="22px" ></app-attachment>
    </button>
    <button [disabled]="!isInteractionAllowed" type="button" class="avatar-mic" (click)="onMicrophoneClick()">
        <app-microphone [strokeColor]="currentMicrophoneColor" width="16px" height="21px"></app-microphone>
    </button>
    <input
        type="text"
        [disabled]="!isInteractionAllowed"
        [(ngModel)]="currentInputValue"
        [placeholder]="currentInputPlaceholder"
        [dir]="textDirection"
        (input)="onInput()"
        (keyup.enter)="onSubmitMessage()"
        *ngIf="!microphone.getMicrophoneStatus()" />
    <div *ngIf="microphone.getMicrophoneStatus()" style="width: 100%;">
        <div id="barContainer" #soundWaveRender [style.width.px]="soundWaveContainerWidth">
            <div *ngFor="let bar of soundWaveList" class="bar" [style.height.%]="bar"></div>
        </div>
    </div>
    <button [disabled]="!isInteractionAllowed" type="button" class="avatar-enter-button" (click)="onSubmitMessage()">
        <img alt="Enter image">
    </button>
</div>
<div style="display: none;">
    <input type="file" class="input-file" (change)="onChange($event)" #fileInput id="file"
           accept=".mp4,.jpg,.png,.docx,.xls,.pdf" />
</div>
