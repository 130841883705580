<div *ngIf="isVisible" class="avatar-shortcut-container">
    <div class="avatar-shortcut-container-wrapper">
        <div class="avatar-shortcut-container-list">
            <div
                *ngFor="let shortcut of shortcutsList"
                class="avatar-shortcut-container-item"
                (click)="onShortcutSubmit(shortcut.text)"
                (keydown)="onShortcutSubmit(shortcut.text)"
                tabindex="0"
                [dir]="textDirection"
            >
                <div class="avatar-shortcut-container-inside">
                    <img [src]="shortcut.iconPath" alt="{{ shortcut.text }}">
                    <p>{{ shortcut.text }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
