import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { TextInputService } from 'app/services/text-input.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { LanguageService } from "app/services/language.service";
import { MessagesService } from "app/services/messages.service";

@Component({
  selector: 'app-avatar-drag-drop',
  templateUrl: './avatar-drag-drop.component.html',
  styleUrl: './avatar-drag-drop.component.scss'
})
export class DragDropComponent extends ComponentAbstract implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
    isVisible: boolean = false;
    dragDropText: string = 'Drag & drop file here or';
    buttonBrowseText: string = 'Browse files';
    supportedFormatsText: string = 'Supported formats: jpg, png, txt, pdf.';
    maxFileSizeText: string = 'Max file size: 6MB.';
    buttonText: string = 'Submit';
    attachmentText: string = 'Upload Attachment';
    fileItems: any[] = [];
    fileItem!: any;
    textDirection!: string;

    visibilitySubscription!: Subscription;
    fileSubscription!: Subscription;
    translateSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private multimedia: MultimediaService,
        private inputText: TextInputService,
        private language: LanguageService,
        private message: MessagesService
    ) {
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-drag-drop').subscribe(visible => {
            this.isVisible = visible;
            if (this.isVisible) {
                this.config.blurEfectEvent.emit(true);
                ["avatar-shortcuts", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
            } else {
                this.config.blurEfectEvent.emit(false);
            }
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale);

            Object.assign(this, translate.typography);
        });

        this.fileSubscription = this.multimedia.getFile().subscribe((file: File) => {
            if (file) { this.handleDroppedFiles(file); }
        });
    }

    hasUploadFiles() {
        return this.fileItems.length > 0;
    }

    onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    onFileDrop(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer?.files;
        this.handleFileList(files)
    }

    openFileInput() {
        this.fileInputRef.nativeElement.click();
    }

    onFileSelect(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        this.handleFileList(files)
    }

    handleFileList(files: FileList | null | undefined) {
        const file = files?.[0];
        if (file) {
            this.handleDroppedFiles(file);
        }
    }

    async submitFile(file: any){
        const type = this.multimedia.getAttachmentType(file.name)
        const fileName = file.name;
        const readableName = fileName
            .replace(/[-_]/g, ' ')
            .replace(/\.[^/.]+$/, '');
        let sentence = '';

        if (type === 'image') {
            sentence = `Here is a scan of my ${readableName}: ${fileName}`;
        } else if (type === 'document') {
            sentence = `Here is my ${readableName} document: ${fileName}`;
        }

        this.visibility.hideComponent("avatar-drag-drop");
        this.closeDragDrop()

        await this.message.createConversationRequest("text", sentence, '', file);
    }

    closeDragDrop() {
        this.fileItems = [];
        this.visibility.hideComponent("avatar-drag-drop");
    }

    //helpers
    handleDroppedFiles(file: File | {}) {
        if (file instanceof File) {
            const src = URL.createObjectURL(file);
            this.addFileItem(file.name, file.size, src);
        }
    }

    addFileItem(name: string, size: number, src: any): void {
        const newItem = { name, size: this.convertBytesToKB(size), showLoad: true, src: src };
        this.fileItem = newItem;
        this.fileItems.push(newItem);

        setTimeout(() => { newItem.showLoad = false; }, 1500);
    }

    convertBytesToKB(bytes: number): string {
        const KB = (bytes / 1024).toFixed(2);
        return KB + ' KB';
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
        this.fileSubscription.unsubscribe();
    }
}
