import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ConfigService } from './config.service';
import { designTranslation } from 'app/config';
import { Language } from "../models/language";

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
    defaultLanguage: Language | null = null;
    designTranslation: any[] = designTranslation
    selectedLanguageSubject = new BehaviorSubject<any>(null);
    selectedLanguage$ = this.selectedLanguageSubject.asObservable();

    constructor(private config: ConfigService) {
        //this.selectedLanguageSubject.next(this.getDefaultLanguage());
        const lang = this.getDefaultLanguage()
        if (lang !== null) { this.setLanguage(lang.locale); }
    }

    //getters
    getSelectedLanguage() {
        return this.selectedLanguageSubject.value || this.getDefaultLanguage();
    }

    getLanguage(selectedLanguage: any) {
        return (
            this.config.getLanguages().find(l => l.locale === selectedLanguage) ?? this.getSelectedLanguage()
        );
    }

    getDefaultLanguage() {
        this.config.getLanguages().forEach(language => {
            if (!this.defaultLanguage && this.config.isSupportedLanguageIcon(language.locale)) {
                this.defaultLanguage = language;
            }
        });
        return this.defaultLanguage;
    }

    getDesignTranslation(locale: string) {
        return this.designTranslation.find(translate => translate.locale === locale) ?? this.getDefaultTranslation()
    }

    getDefaultTranslation() {
        return this.designTranslation.find(translate => translate.locale === this.getDefaultLanguage()?.locale)
    }

    //setters
    setLanguage(locale: string): void {
        // TODO: Remove when fixed in BE
        if (!locale) {
            console.error('Language locale is not defined.');
            return;
        }

        const selectedLanguage = this.getLanguage(locale);
        this.selectedLanguageSubject.next(selectedLanguage);

        this.config.setPresenter(locale);
        this.config.setAvatarIcon(selectedLanguage.voices[0].gender);
    }
}
