import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from "rxjs";

import { WebSocketMessage } from "app/models/webSocketMessage";
import { ComponentAbstract } from "app/components/component.abstract";
import { AvatarAudioService } from "app/services/avatar-audio.service";
import { ConfigService } from "app/services/config.service";
import { WebSocketService } from "app/services/web-socket.service";
import { AvatarInteractionService } from "app/services/avatar-interaction.service";
import { MessagesService } from "app/services/messages.service";
import { InteractionService } from "app/services/interaction.service";
import { LanguageService } from "app/services/language.service";

@Component({
  selector: 'app-avatar-conversation-board',
  templateUrl: './avatar-conversation-board.component.html',
  styleUrl: './avatar-conversation-board.component.scss'
})
export class AvatarConversationBoardComponent extends ComponentAbstract implements OnInit, OnDestroy, AfterViewInit  {
    @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
    @ViewChild('welcomeAudioMessage') welcomeAudioMessage!: ElementRef;

    canWelcomeAudioBePlayed: boolean = false;
    assistantName: string = 'Exafy Avatar';
    isLoadingShown: boolean = false;
    isVisible: boolean = false;
    isBlured: boolean = false;
    avatarIcon: any;

    visibilitySubscription!: Subscription;
    loadingBarSubscription!: Subscription;
    socketMessageSubscription!: Subscription
    audioLoadedSubscription!: Subscription;
    iconSubscription!: Subscription;

    queueResponseMessage!: WebSocketMessage

    constructor(
        config: ConfigService,
        private audio: AvatarAudioService,
        private socket: WebSocketService,
        private visibility: AvatarInteractionService,
        private message: MessagesService,
        private changeDetector: ChangeDetectorRef,
        private interaction: InteractionService,
        private language: LanguageService
    ) {
        super(config);
        this.assistantName = this.config.getAgentName();
    }

    ngOnInit(): void {
        this.iconSubscription = this.config.getAvatarIcon().subscribe(icon => {
            if (icon) { this.avatarIcon = icon; }
        });

        this.config.scrollToBottomEvent.subscribe(() => {
            this.scrollToBottom();
        });

        this.config.blurEfectEvent.subscribe(isBlured => {
            this.isBlured = isBlured
        });

        this.visibilitySubscription = this.visibility
            .getVisibility('avatar-conversation-board').subscribe(visible => {
                this.isVisible = visible;
            });

        this.loadingBarSubscription = this.visibility
            .getVisibility('avatar-loading').subscribe(visible => {
                this.isLoadingShown = visible;
            });

        this.socketMessageSubscription = this.socket.message
            .subscribe(message => {
                if (this.language.getSelectedLanguage().locale !== message.language){
                    this.language.setLanguage(message.language);
                }

                if (!message.audioAnswer) {
                    this.message.setMessage("ai", message);
                    this.visibility.hideComponent("avatar-loading");
                    this.scrollToBottom();
                } else {
                    this.setQueueMessage(message);
                    this.audio.playAudio(message.audioAnswer);
                }
            });

        this.audioLoadedSubscription = this.audio.onAudioLoaded.subscribe((loaded)=> {
            if (loaded) {
                this.message.setMessage("ai", this.getQueueMessage());
                this.visibility.hideComponent("avatar-loading");
                this.scrollToBottom();
            }
        });

        combineLatest([this.audio.isAudioPlaying, this.visibility.getVisibility('avatar-loading')])
            .subscribe(([isAudioPlaying, isLoadingShown]) => {
                this.interaction.setInteractionMode(!(isAudioPlaying || isLoadingShown));
            });

        this.message.setWelcomeMessage(this.language.getSelectedLanguage().locale)
        this.scrollToBottom();
    }

    async ngAfterViewInit() {
        if (!this.config.getFirstTime()) {
            this.prepareWelcomeAudio();
            this.scrollToBottom();
            this.config.setFirstOpen();
        }
    }

    prepareWelcomeAudio () {
        const element = this.getAudioElement();
        const welcomeAudio = this.config.getWelcomeAudio(this.language.getSelectedLanguage().locale);

        if (!welcomeAudio || welcomeAudio.blob == null) {
            throw new Error(`Welcome audio is null or doesn't exists!`);
        }

        element.src = welcomeAudio.source;

        element.play().then(() => {
            this.audio.isAudioPlaying.next(true);
        }).catch((error:any) => {
            console.error('Error playing audio:', error);
        });
    }

    // getters
    getQueueMessage (): WebSocketMessage {
        return this.queueResponseMessage;
    }

    // setters
    setQueueMessage (message: WebSocketMessage) {
        this.queueResponseMessage = message;
    }

    // events
    onCanBePlayed () {
        this.canWelcomeAudioBePlayed = true
    }

    onAudioPlaybackEnded () {
        (this.getAudioElement()).pause();
        this.audio.isAudioPlaying.next(false);
    }

    //helpers
    scrollToBottom () {
        this.changeDetector.detectChanges();
        if (this.scrollContainer) {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    getAudioElement() {
        return this.welcomeAudioMessage && this.welcomeAudioMessage.nativeElement
            ? this.welcomeAudioMessage.nativeElement
            : (console.error('Audio element does not exist.'));
    }

    ngOnDestroy () {
        this.visibilitySubscription.unsubscribe();
        this.loadingBarSubscription.unsubscribe();
        this.audioLoadedSubscription.unsubscribe();
        this.socketMessageSubscription.unsubscribe();
        this.iconSubscription.unsubscribe();
    }
}
