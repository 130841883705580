import { ConfigService } from 'app/services/config.service';
import { ComponentInterface } from './component.interface';

export class ComponentAbstract implements ComponentInterface {
    id: string = '';
    config: ConfigService;

    constructor(config: ConfigService) {
        this.config = config;
    }

    isMobileDevice(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    getComponentDesign(): object | boolean {
        return false;
    }

    getComponentTranslation(): [] {
        return [];
    }

    getComponentDesignId(): string {
        return this.config.getDesignId();
    }

    getEnvironment(): object {
        return {};
    }

    isComponentEnabled(): boolean {
        return this.config.isComponentEnabled(this.id);
    }

    getProjectName(): string {
        return this.config.getProjectName();
    }
}
export class CA extends ComponentAbstract {}
