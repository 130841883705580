import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { MultimediaData } from 'app/models/multimediaData';
import { HttpService } from './http.service';

type MediaType = 'image' | 'video' | 'document' | 'url' | 'base64';

@Injectable({
  providedIn: 'root',
})
export class MultimediaService {
    mediaUrl: string = '/url_media/';
    fileSelected = new BehaviorSubject<any>(null);
    multimediaDataSubject = new Subject<{
        mediaType: string;
        source: string;
    }>();
    fileSelected$ = this.fileSelected.asObservable();
    multimediaData$ = this.multimediaDataSubject.asObservable();

    // Temporary for demo
    multimediaDataList: MultimediaData[] = [
        { mediaType: 'image', source: './assets/images/Cat.png' },
        { mediaType: 'video', source: './assets/docs/video.mp4' },
        { mediaType: 'document', source: './assets/docs/samplePDF.pdf' }
    ];

    constructor(private http: HttpService) {}

    //getters
    getFile(): Observable<File> {
        return this.fileSelected;
    }

    getAttachmentType(fileName: string) {
        const extension = fileName.split('.').pop()!.toLowerCase();
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return 'image';
            case 'pdf':
            case 'txt':
                return 'document';
            default:
                return 'unknown';
        }
    };

    // Temporary for demo
    getMedia(mediaType: string): any {
        const foundMedia = this.multimediaDataList.find(
            (item) => item.mediaType === mediaType,
        );
        if (foundMedia) {
            return foundMedia;
        }
    }

    //setters
    setFileValue(newValue: any): void {
        this.fileSelected.next(newValue);
    }

    emitMediaData(mediaType: string, source: any): void {
        this.multimediaDataSubject.next({ mediaType, source });
    }
}
