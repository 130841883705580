<div *ngIf="isVisible" class="avatar-container">
    <div class="avatar-background-container">
        <app-avatar-heading></app-avatar-heading>
        <app-avatar-shortcuts></app-avatar-shortcuts>
        <app-avatar-feedback></app-avatar-feedback>
        <app-avatar-drag-drop></app-avatar-drag-drop>
        <app-avatar-conversation-board></app-avatar-conversation-board>
        <app-avatar-controls></app-avatar-controls>
    </div>
</div>

<app-modal-content idModal="idModal" labelModal="labelModal"></app-modal-content>
