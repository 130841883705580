import {Component, OnDestroy, OnInit} from '@angular/core';

import { ConfigService } from 'app/services/config.service';
import { MessagesService } from 'app/services/messages.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { AvatarInteractionService } from "app/services/avatar-interaction.service";
import { MessageDisplayFormat } from "app/models/messageDisplayFormat";
import { ComponentAbstract } from "app/components/component.abstract";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrl: './message.component.scss'
})
export class MessageComponent extends ComponentAbstract implements OnInit, OnDestroy {
    clickedThumbColor: string = 'rgba(85, 152, 254, 1)';
    defaultThumbColor: string = 'rgba(49, 64, 90, 1)';
    isHoveredThumbUp: boolean = false;
    isHoveredThumbDown: boolean = false;
    avatarIcon: any;

    iconSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        protected messages: MessagesService,
        private multimedia: MultimediaService
    ) {
        super(config)
    }

    ngOnInit() {
        this.iconSubscription = this.config.getAvatarIcon().subscribe(icon => {
            if (icon) { this.avatarIcon = icon; }
        });
    }

    emitMediaType(mediaType: string, source: string) {
        this.multimedia.emitMediaData(mediaType, source);
    }

    positiveReaction(index: number) {
        this.messages.setMessageFeedback(index, true)
    }

    negativeReaction(index: number) {
        this.messages.setMessageIndex(index);
        this.visibility.showComponent("avatar-feedback");
    }

    handleThumbDownColor(message: MessageDisplayFormat, hoveredThumbDown: boolean): string {
        return this.handleThumbColor(message, hoveredThumbDown);
    }

    handleThumbUpColor(message: MessageDisplayFormat, hoveredThumbUp: boolean): string {
        return this.handleThumbColor(message, hoveredThumbUp);
    }

    //helpers
    handleThumbColor(message: MessageDisplayFormat, hovered: boolean): string {
        return (message.feedback !== undefined) ? this.clickedThumbColor :
            (hovered ? this.clickedThumbColor : this.defaultThumbColor);
    }

    ngOnDestroy () {
        this.iconSubscription.unsubscribe();
    }
}
