import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { ComponentAbstract } from "app/components/component.abstract";
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss'
})
export class AvatarComponent extends ComponentAbstract implements OnInit, OnDestroy {
    isVisible: boolean = false;

    visibilitySubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService
    ){
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar').subscribe(visible => {
            this.isVisible = visible;

            if (this.isVisible) {
                ["avatar-controls", "avatar-conversation-board"].map(key => this.visibility.showComponent(key));
                ["avatar-shortcuts", "avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
            } else {
                this.visibility.showComponent("avatar-init");
                ["avatar-controls", "avatar-shortcuts"].map(key => this.visibility.hideComponent(key));
            }
        });
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
    }
}
