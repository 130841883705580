import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class TextInputService {
    inputUrl: string = '/url_get_input_text/';
    messages: any[] = [
        {text: 'Hello, this is Exafy Avatar Layla. How can I help you today?'},
    ];

    constructor(private http: HttpService) {}

    //getters
    getMessages() {
        return this.messages;
    }

    getInputTextAnswer(inputText: any) {
        return this.http.httpGetObject(this.inputUrl, inputText);
    }

    //setters
    setMessage(newValue: any): void {
        if (newValue != null) {
            if (typeof newValue === 'string') {
                this.messages.push({text: newValue});
            } else {
                this.messages.push({file: newValue});
            }
        }
        console.log(this.messages)
    }
}
