import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import { ConfigService } from 'app/services/config.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { ComponentAbstract  } from 'app/components/component.abstract';

@Component({
    selector: 'app-modal-content',
    templateUrl: './content-modal.component.html',
    styleUrl: './content-modal.component.scss'
})
export class ContentModalComponent extends ComponentAbstract implements OnInit {
    @ViewChild('videoModal') videoPlayer!: ElementRef;
    @Input() idModal!: string;
    @Input() labelModal!: string;
    mediaType!: string;
    mediaSource!: string;

    constructor(
        config: ConfigService,
        private multimedia: MultimediaService,
    ){
        super(config);
    }

    ngOnInit(): void {
        this.multimedia.multimediaData$.subscribe(
            ({ mediaType, source }) => {
                this.mediaType = mediaType;
                this.mediaSource = source;
            },
        );
    }

    closeModal() {
        if (this.videoPlayer){
            this.videoPlayer.nativeElement.pause();
            this.videoPlayer.nativeElement.currentTime = 0;
        }
    }
}
